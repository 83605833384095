@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);
body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Noto Sans TC',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* ----------------------------------------------------- */
/*                                                       */
/*                        BASIC                          */
/*                                                       */
/* ----------------------------------------------------- */
#root {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding: 0px 0px;
}

.app-set {
    display: grid;
    grid-template-rows: 103px;
    grid-template-columns: 72px 72px 72px;

    grid-row-gap: 8px;
    grid-column-gap: 26px;

    overflow-x: hidden;
    overflow-y: auto;
}

.app-container {
    display: grid;
}

.app-icon-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.app-icon {
    width: 50px;
    height: 50px;
    padding: 6px;
    border-radius: 10px;
    border: 1px solid #E4E5E6;
}

.app-text-container {
    display: flex;
    justify-content: center;
}

.app-text {
    width: 100%;
    height: 34px;

    text-align: center;
    font-size: 12px;
}

.app-text-no-permission {
    font-family: monospace;
    background: rgba(238, 238, 238, .9);
    border: 1px dashed grey;
    border-radius: 8px;

    width: 54px;
    height: 54px;

    margin: 5px;

    font-size: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: darkgray;

    cursor: not-allowed;
}

.user-logo {
    font-family: monospace;
    font-size: 12px;
    width: 56px;
    height: 56px;
    line-height: 56px;
    border-radius: 50%;
    text-align: center;
    background: antiquewhite;
    color: slategray;
}

/* ----------------------------------------------------- */
/*                                                       */
/*                        EFFECTS                        */
/*                                                       */
/* ----------------------------------------------------- */
.app-icon:hover, .user-logo:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2px 2px;
    cursor: pointer;
}

.non-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* ----------------------------------------------------- */
/*                                                       */
/*                        RWD                            */
/*                                                       */
/* ----------------------------------------------------- */
@media (max-width: 576px) and (orientation: portrait) {
    .app-set {
        padding: 28px 26px;
    }
}

@media (min-width: 577px) and (orientation: portrait) {
    .app-set {
        padding: 28px 26px;
    }
}

@media (max-height: 576px) and (orientation: landscape) {
    .app-set {
        padding: 12px 16px;
    }
}

@media (min-height: 577px) and (orientation: landscape) {
    .app-set {
        padding: 28px 26px;
    }
}

